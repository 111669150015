import { start, registerApplication } from 'single-spa'
import 'babel-polyfill'
import { Modules } from './enums/Modules'

const matchPrefixURL = prefix => {
  return location => location.pathname.includes(`${prefix}`)
}

const publicPath = '/payroll-loan-status'

registerApplication(
  Modules.COMMON_NAME,
  () => window.System.import(`${publicPath}/${Modules.COMMON_NAME}.js`),
  () => true,
)

const ClientDeductions = Modules.CLIENT_DEDUCTIONS_NAME
const authToken =
  'Bearer eyJraWQiOiIyZWRkZGQyYSIsImFsZyI6IkVkRFNBIn0.eyJzdWIiOiI4MDRhMWZkYS1kYWJlLTQ2MTItODZiMi1lYjFjNzJhZTg5N2IiLCJ1c2VybmFtZSI6IjgwNGExZmRhLWRhYmUtNDYxMi04NmIyLWViMWM3MmFlODk3YiIsInNlc3Npb25faWQiOiJiNmE2MDVhOC04OTA4LTQ2YzQtODM2NC01NDdmMjE2OWY4MTgiLCJ1c2VyX3R5cGUiOiJhY2NvdW50IiwiaWF0IjoxNjYyNDkyNTE4LCJleHAiOjE2NjI0OTk3MTgsImlzcyI6Imh0dHBzOi8vYXV0aC1zdGFnaW5nLmNyZWRpdGFzLmNvbS5iciIsImp0aSI6InNoV1FJVjlXIiwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiJ9.o_y0govEYDABl1hEv8lMGdJLFjToVfcNfsmW8A33GEC4f10PCQ91m6uaSy5-nPWVmX8D1wplrdwz8B08h6YCCA'
const clientId = 110602

registerApplication(
  ClientDeductions,
  () => window.System.import(`${publicPath}/${ClientDeductions}.js`),
  matchPrefixURL(ClientDeductions),
  {
    api: 'DEXTER',
    language: 'ptBr',
    authToken,
    clientId,
  },
)

const ProductCards = Modules.PRODUCT_CARDS_NAME

registerApplication(
  ProductCards,
  () => window.System.import(`${publicPath}/${ProductCards}.js`),
  matchPrefixURL(ProductCards),
  {
    api: 'DEXTER',
    language: 'ptBr',
    authToken,
    clientId,
  },
)

const Notifications = Modules.NOTIFICATIONS_NAME

registerApplication(
  Notifications,
  () => window.System.import(`${publicPath}/${Notifications}.js`),
  matchPrefixURL(Notifications),
  {
    api: 'DEXTER',
    language: 'ptBr',
  },
)

start()
